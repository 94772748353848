import { getCommonParamsItemLabel } from '@/utils/common.js'
export const contentTableConfig = {
  propList: [
    {
      prop: 'userName',
      label: 'customer.customer-name',
      width: 150
    },
    // {
    //   prop: 'firstName',
    //   label: 'user.first-name'
    // },
    // {
    //   prop: 'lastName',
    //   label: 'user.last-name'
    // },
    {
      prop: 'name',
      label: 'user.name',
      width: 250
    },
    {
      prop: 'tenantName',
      label: 'file.tenant'
    },
    {
      prop: 'email',
      label: 'user.email',
      width: 200
    },
    {
      prop: 'mobile',
      label: 'user.phone',
      width: 150
    },
    {
      prop: 'status',
      label: 'general.status',
      width: 120,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'customer.customer-operation',
      width: '390',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showColSetting: true,
  tableColCheckedListName: 'customerTableCheckedCol',
  tableColAllListName: 'customerTableAllCol'
}
